import { Link, Navigate, NavLink, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

function Box({ children, ...props }) {
  return <div {...props}>{children}</div>;
}

const TimetablePreviewCard = (props) => {
  const nav = useNavigate();

  const toggleHandler = () => {
    localStorage.setItem("year", `${props.year.replace(/ /g, "")}`);

    console.log(localStorage.getItem("year"));
  };

  return (
    <Link
      to={!props.preview ? `/timetable/${props.id.replace(/ /g, "")}` : "/"}
      onClick={toggleHandler}
    >
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{
          //   type: "spring",
          duration: 0.5,
        }}
        className="timetable-preview__item"
        style={
          props.preview
            ? { backgroundColor: "black" }
            : { transition: "all 0.2s" }
        }
      >
        <div className="bgyear">
          <h3 className="timetable-preview__year">Year {props.year}</h3>
        </div>
      </motion.div>
      {/* </image> */}
    </Link>
  );
};

export default TimetablePreviewCard;
