import FooterWebsite from "../components/Wrapper-Components/Wrapper-1/FooterWebsite_1";
import HeaderWebsite_3 from "../components/Wrapper-Components/Wrapper-3/HeaderWebsite_3";

const ForgotPassword = () => {
  return (
    <div>
      {/* <HeaderWebsite_3 /> */}
      <div style={{ justifyContent: "center" }}></div>
      <FooterWebsite />
    </div>
  );
};

export default ForgotPassword;
