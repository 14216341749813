import react from "react";
import { Link, Navigate } from "react-router-dom";
import { motion } from "framer-motion";

export default function GlanceBox(props) {
  return (
    <motion.div
      style={
        props.curClass ? { background: "#0E6BA8" } : { background: "#FF8600" }
      }
      className="glance-item"
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{
        type: "spring",
        duration: 1,
      }}
    >
      <h3>
        {props.curClass ? "Current Class:" : "Next Class:"} <br />{" "}
        {props.className}{" "}
      </h3>
      {/* <Link to={`/timetable/${props.timetableId}`}> */}
      <button
        className="glance-item__btn"
        onClick={() =>
          alert(
            "Not supported yet in this version,\nwill be avaliable by the time of the release."
          )
        }
      >
        View In Timetable
      </button>
      {/* </Link> */}
    </motion.div>
  );
}
